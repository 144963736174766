import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import './companyList.scss';
import { RootState } from '../../store/store';
import { fetchAPI } from '../../Api';
import { useParams } from 'react-router-dom';
// @ts-ignore
import ReactFileReader from 'react-file-reader';
import { Company, CompanyResponse, ImportCostCode, ImportEmployee, ImportJobSite } from "../../store/interfaces";

const CompanyPage: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const params = useParams();

  const companyId = params.id;

  const [company, setCompany] = React.useState<CompanyResponse | null>(null);

  useEffect(() => {
    async function fetchData() {
      if (!user) {
        return;
      }

      try {
        const response = await fetchAPI(user, 'GET', 'companies/' + companyId);

        if (!response.ok) {
          throw new Error('Fetch Company Failed');
        }

        const data = await response.json();
        console.log('Get Company successful', data);
        setCompany(data);
      } catch (error) {
        console.error('Get Company error:', error);
      }
    }

    fetchData();
  }, [companyId, user]);

  console.log('role', user?.role);
  console.log('company', company);

  if (user?.role !== 'SUPERADMIN') {
    return <p>Not authorized</p>;
  }

  if (!company) {
    return <p>Fetching company...</p>;
  }

  const handleEmployeeFile = (companyId: number) => {
    return (files: File[]) => {
      var reader = new FileReader();
      reader.onload = function (e) {
        if (!reader.result || typeof reader.result !== 'string') {
          return;
        }
        const lines = reader.result.split('\n');
        const employees: ImportEmployee[] = lines.map((line: string) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [lastName, middleInitial, firstName, companyEmployeeId, status, position, phone] = line.split('\t');
          const fixedPhone = phone?.replace(/[^0-9]/g, '');
          return {
            companyEmployeeId,
            name: `${firstName} ${middleInitial} ${lastName}`,
            companyId,
            active: !position?.length,
            phone: fixedPhone,
            position
          } as ImportEmployee;
        });
        // submit each jobSite to the API
        employees.forEach(async (employee: ImportEmployee) => {
          const response = await fetchAPI(user, 'POST', `employees`, employee);
          if (!response.ok) {
            console.error('Failed to create jobSite', employee);
          }
        });
      }
      reader.readAsText(files[0]);
    }
  }

  const handleCostCodeFile = (companyId: number) => {
    return (files: File[]) => {
      var reader = new FileReader();
      reader.onload = function (e) {
        if (!reader.result || typeof reader.result !== 'string') {
          return;
        }
        const lines = reader.result.split('\n');
        const costCodes = lines.map((line: string) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          let [code, description, unit, division, compCode1, maxWage, compCode2, department, inactive, notes] = line.split('\t');

          return {
            code,
            companyId,
            active: (!inactive || inactive.trim().length === 0),
            compCode1,
            compCode2,
            description,
            unit,
            division,
            maxWage
          } as ImportCostCode;
        });
        // submit each jobSite to the API
        costCodes.forEach(async (costCode: ImportCostCode) => {
          if (costCode.code) {
            const response = await fetchAPI(user, 'POST', `costCodes`, costCode);
            if (!response.ok) {
              console.error('Failed to create costCode', costCode);
            }
          }
        });
      }
      reader.readAsText(files[0]);
    }
  }

  const handleJobSiteFile = (companyId: number) => {
    return (files: File[]) => {
      var reader = new FileReader();
      reader.onload = function (e) {
        if (!reader.result || typeof reader.result !== 'string') {
          return;
        }
        const lines = reader.result.split('\n');
        const jobSites = lines.map((line: string) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          let [jobNumber, name, _client, _architect, supervisor, ...rest] = line.split('\t');
          while (rest.length && supervisor?.indexOf(' - ') === -1) {
            const newSupervisor = rest.shift();
            if (newSupervisor?.indexOf(' - ') !== -1) {
              if (newSupervisor) {
                supervisor = newSupervisor;
                break;
              }
            }
          }
          return { name, jobNumber, supervisor, companyId } as ImportJobSite;
        });
        // submit each jobSite to the API
        jobSites.forEach(async (jobSite: ImportJobSite) => {
          if (jobSite.jobNumber) {
            const response = await fetchAPI(user, 'POST', `jobSites`, jobSite);
            if (!response.ok) {
              console.error('Failed to create jobSite', jobSite);
            }
          }
        });
      }
      reader.readAsText(files[0]);
    }
  }

  console.log(company.company.Employee)

  return (company && company.company.id) ? (
    <div className={"company-container"}>
      <div className="company-list">
        <div className={clsx('company-item-x', 'company-header')}>
          <div className="company-name">Details</div>
          <div className="company-location">Upload/Edit</div>
        </div>
        <div className="company-item-x">
          <div className="company-name">{company.company.name}<br />{company.company.location}</div>
          <div className="company-employees">
            <ReactFileReader handleFiles={handleEmployeeFile(company.company.id)} fileTypes={['.tsv', '.tab']}>
              <button className='btn'>Upload Employees</button>
            </ReactFileReader>
            <div className="align-top">
              {company.company.Employee && company.company.Employee.map((employee, index) => {
                return <div key={index}>{employee.name} ({employee.Position.type})</div>
              })}
            </div>
          </div>
          <div className="company-location">
            <ReactFileReader handleFiles={handleCostCodeFile(company.company.id)} fileTypes={['.tsv', '.tab']}>
              <button className='btn'>Upload Cost Codes</button>
            </ReactFileReader>
            <div className="align-top">
              {company.company.CostCode && company.company.CostCode.map((costCode, index) => {
                return <div key={index}>{costCode.code}</div>
              })}
            </div>
          </div>
          <div className="company-view">
            <ReactFileReader handleFiles={handleJobSiteFile(company.company.id)} fileTypes={['.tsv', '.tab']}>
              <button className='btn'>Upload Job Sites</button>
            </ReactFileReader>
            <div className="align-top">
              {company.company.JobSite && company.company.JobSite.map((jobSite, index) => {
                return <div key={index}>{jobSite.name}</div>
              })}
            </div>
          </div>
        </div>
      </div>
    </div>) : <>Loading Company...</>;
};

export default CompanyPage;
