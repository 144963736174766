import { User } from './store/userSlice';

export const fetchAPI = async (
  user: User | undefined,
  method: string,
  path: string,
  data?: any,
): Promise<Response> => {
  const baseUrl = process.env.REACT_APP_API_URL;
  if (!baseUrl) {
    throw new Error('API URL is not configured. Please set REACT_APP_API_URL in your .env file');
  }

  const url = `${baseUrl}/${path}`;
  const headers = user
    ? {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.token,
    }
    : {
      'Content-Type': 'application/json',
      Authorization: 'None',
    };

  console.log('fetchAPI', url, method, data, headers);

  try {
    const response = await fetch(url, {
      method,
      body: data ? JSON.stringify(data) : undefined,
      headers,
    });

    return response;
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};
