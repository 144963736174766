import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './checkin.scss';
import { fetchAPI } from '../../Api';
import { useGeolocated } from "react-geolocated";
import { computeDistance } from "../../pieces/functions";

const CheckinPage: React.FC = () => {
  console.log('CheckinPage')
  const [phoneCode, setPhoneCode] = useState('');
  const [checkedIn, setCheckedIn] = useState<{ [key: string]: any; } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [jobSite, setJobSite] = useState<any | null>(null);
  const [ipAddress, setIpAddress] = useState<string | null>(null);
  const params = useParams();

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  // Add a state for location warning
  const [showLocationWarning, setShowLocationWarning] = useState(false);

  useEffect(() => {
    const fetchJobSite = async () => {
      try {
        console.log('Fetching job site with ID:', params.jobSiteId);
        console.log('API URL:', process.env.REACT_APP_API_URL);

        const results = await fetchAPI(undefined, 'GET', `jobsites/${params.jobSiteId}`);
        console.log('Raw API response:', results);

        if (!results.ok) {
          const errorText = await results.text();
          console.error('API Error Response:', errorText);
          throw new Error(`HTTP error! status: ${results.status}, message: ${errorText}`);
        }

        const data = await results.json();
        console.log('JobSite data:', data);
        setJobSite(data);
      } catch (error: unknown) {
        console.error('Detailed error fetching job site:', error);
        // Type guard to check if error is an Error object
        const errorMessage = error instanceof Error
          ? error.message
          : 'An unknown error occurred';
        setError(`Unable to fetch job site information: ${errorMessage}`);
      }
    };

    const fetchIpAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error: unknown) {
        console.error('Error fetching IP address:', error);
        // Don't set error state here as IP is not critical
      }
    };

    if (params.jobSiteId) {
      console.log('Starting job site fetch for ID:', params.jobSiteId);
      fetchJobSite();
      fetchIpAddress();
    } else {
      console.warn('No jobSiteId provided in params');
      setError('No job site ID provided');
    }
  }, [params.jobSiteId]);

  console.log('Got Coords', coords, isGeolocationAvailable, isGeolocationEnabled)

  const handleCheckin = async (e: React.FormEvent) => {
    console.log('handleCheckin')
    e.preventDefault();
    setError(null); // Reset error state

    // Call the API to check in the user
    try {
      const response = await fetchAPI({
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        token: 'X',
        companyId: 0
      }, 'POST', 'users/checkin', {
        jobSiteId: Number(params.jobSiteId),
        todayDate: params.todayDate,
        phoneCode: phoneCode,
        foremanId: Number(params.foremanId),
        ipAddress: ipAddress // Include the IP address in the request
      });

      console.log('response', response)

      if (!response.ok) {
        setError(`Checkin failed: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Checkin response', data);
      if (data) {
        console.log('setting checked in to', data)
        setCheckedIn(data);
      }
      // Dispatch user data to Redux store

    } catch (error) {
      console.error('Login error:', error);
      setError('Login failed. Please check your credentials.');
    }
  };

  // Remove the early return for geolocation check and replace with a warning
  useEffect(() => {
    // Temporarily disabled geographic check
    setShowLocationWarning(false);

    /* Original geographic check code commented out
    if (!isGeolocationEnabled || !coords?.latitude || !coords?.longitude || !jobSite?.latitude || !jobSite?.longitude) {
      setShowLocationWarning(true);
    } else {
      setShowLocationWarning(false);
      // Calculate distance if we have coordinates
      const distance = computeDistance(coords?.latitude, coords?.longitude, jobSite?.latitude, jobSite?.longitude);
      if (distance > 100) {
        setShowLocationWarning(true);
      }
    }
    */
  }, [coords, isGeolocationEnabled, jobSite]);

  return (
    <div className="checkin-page">
      <div className="checkin-container">
        {showLocationWarning && (
          <div className="warning-message">
            <p>⚠️ Location services are not enabled or you are not near the job site.
              This may affect your ability to properly check in. Please enable location services if possible.</p>
          </div>
        )}

        {checkedIn === null && (
          <form className="checkin-form" onSubmit={handleCheckin}>
            <h2 className="checkin-title">Check-In</h2>
            {error && <p className="error-message">{error}</p>}
            <div className="form-group">
              <label htmlFor="phoneCode">Phone Number / Número de Teléfono</label>
              <input
                type="tel"
                id="phoneCode"
                autoFocus={true}
                value={phoneCode}
                onFocus={() => setError(null)}
                onChange={(e) => setPhoneCode(e.target.value)}
                required
                pattern="[0-9]*"
                inputMode="numeric"
                placeholder="Enter your phone number"
              />
            </div>
            <button type="submit" className="checkin-button">
              Check In
            </button>
          </form>
        )}

        {checkedIn?.statusCode >= 300 && (
          <div className="error-message">
            <h2>Error Checking in. {checkedIn?.statusCode}</h2>
            <p>{JSON.stringify(checkedIn)}</p>
          </div>
        )}

        {checkedIn !== null && !checkedIn?.statusCode && (
          <div className="success-message">
            <h2>Welcome {checkedIn.name}, you are Checked In!</h2>
          </div>
        )}

        {ipAddress && <p className="ip-address">Your IP: {ipAddress}</p>}
      </div>
    </div>
  );
};

export default CheckinPage;
